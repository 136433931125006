



















import { Component, Prop } from 'vue-property-decorator';
import { PropType } from 'vue';
import { BaseView } from '@shared/views/BaseView';
import { Pro } from '@shared/models';

@Component({})
export default class CompanyItem extends BaseView {

	@Prop({ type: Object as PropType<Pro> })
	public model: Pro;

	@Prop({ default: 50 })
	public size: number;

	@Prop({ default: true })
	public showName: boolean;

	@Prop({ default: 14 })
	public nameSize: number;

	@Prop({ default: 0 })
	public counter: number;

	public imageUrl: string = '';


	public mounted() {
		this.imageUrl = `https://lurker.blob.core.windows.net/pros/d_pro_${this.model.id}.jpg`;
	}

	public handleBrokenImage($event: any) {
		$event.target.src = `${this.settings.baseUrl}/Assets/Stubs/default-pro.jpg`;
	}

}

